<template>
  <el-dialog
    v-model="dialogVisible"
    :close-on-click-modal="false"
    :title="title"
    width="470px"
  >
    <el-row>
      <el-col :span="24">
        <vab-cropper-beta
          ref="vabCropper"
          :options="options"
          :origin-img="slide.oriUrl"
          :preview-img="slide.preUrl"
          @get-vab-cropper="getCropper"
        />
      </el-col>
    </el-row>

    <template #footer>
      <div class="vab-cropper-footer">
        <el-button type="primary" @click="upload">上传并保存</el-button>
        <el-upload
          accept="image/jpeg,image/gif,image/png"
          action="#"
          :auto-upload="false"
          list-type="picture-card"
          :on-change="onChange"
        >
          <template #default>
            <el-button type="primary">选择图片</el-button>
          </template>
        </el-upload>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { defineComponent } from 'vue'
  import { mapActions } from 'vuex'
  import VabCropperBeta from 'vab-cropper'
  import 'vab-cropper/lib/vab-cropper.css'
  import { upLoadImg } from '@/api/active'

  export default defineComponent({
    name: 'VabCropper',
    components: { VabCropperBeta },
    props: {
      url: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '头像裁剪',
      },
      options: {
        type: Object,
        default: {},
      },
    },
    emits: ['upDateUserInfoFunc'],
    data() {
      return {
        dialogVisible: false,
        value: '',
        fileName: {},
        slide: {
          oriUrl: '',
          preUrl: '',
        },
        timer: null,
      }
    },
    methods: {
      ...mapActions({
        setAvatar: 'user/setAvatar',
      }),
      getCropper(argument) {
        if (this.timer) clearInterval(this.timer)
        this.timer = setTimeout(() => {
          this.slide.preUrl = argument
          this.value = argument
        }, 10)
      },
      onChange(file) {
        this.fileName = file.name
        this.slide = {
          oriUrl: file.url,
          preUrl: file.url,
        }
        this.$refs['vabCropper'].drawImg()
      },
      upload() {
        // this.$baseMessage('模拟保存成功', 'success', 'vab-hey-message-success')
        var formData = new FormData()
        let blob = this.dataURLtoBlob(this.value)
        this.blobToDataURI(blob, async (e) => {
          formData.append('file', e)
          console.log(e)
          try {
            const { data } = await upLoadImg(formData)

            // this.$refs['vabCropper'].cancelCropper()
            this.slide = {
              oriUrl: '',
              preUrl: '',
            }
            this.setAvatar(data)
            this.$emit('upDateUserInfoFunc', data)
            this.dialogVisible = false
          } catch (e) {
            this.$baseMessage(e.msg, 'error')
            // this.dialogVisible = false
          }
        })
      },
      dataURLtoBlob(dataurl) {
        var arr = dataurl.split(',')
        var _arr = arr[1].substring(0, arr[1].length - 2)
        var mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(_arr),
          n = bstr.length,
          u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], {
          type: mime,
        })
      },
      blobToDataURI(blob, callback) {
        var _this = this
        var reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = function (e) {
          let urlData = e.target.result
          var arr = urlData.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }
          let imgFile = new File([u8arr], _this.fileName, { type: mime })
          callback(imgFile)
        }
      },
      /**
       * 利用Canvas方式把图片URL转换为图片Base64字符串
       * @param imgUrl 图片URL
       * @param callback 回调函数异步接收图片Base64字符串
       */
      urlToBase64ByCanvas(imgUrl, callback) {
        const xhr = new XMLHttpRequest()
        xhr.open('get', imgUrl, true)
        xhr.responseType = 'blob'
        xhr.onload = function () {
          if (this.status == 200) {
            const blob = this.response
            const oFileReader = new FileReader()
            oFileReader.onloadend = function (e) {
              if (e.target) {
                const base64 = e.target.result
                if (callback) {
                  callback(base64)
                }
              }
            }
            oFileReader.readAsDataURL(blob)
          }
        }
        xhr.send()
      },
    },
  })
</script>

<style lang="scss" scoped>
  :deep() {
    @media only screen and (max-width: 767px) {
      .vab-cropper-canvas {
        display: block;
        float: none;
        margin: 0 auto;
      }
      .vab-cropper-preview {
        display: none;
      }
    }

    .el-textarea {
      margin-top: $base-margin;
    }
  }
</style>
<style lang="scss">
  /* 未知原因深度选择器不好使，临时全局复写样式！！！！！！#TODO */
  .el-dialog__footer {
    height: 62px;

    &:before {
      display: block;
      clear: both;
      content: '';
    }

    > .vab-cropper-footer {
      > div {
        display: inline;

        .el-upload-list {
          display: none;
        }

        .el-upload--picture-card {
          float: right;
          width: auto;
          height: $base-input-height;
          line-height: $base-input-height;
          vertical-align: middle;
          background-color: transparent;
          border: 0;
          border-radius: 0;
        }

        .el-button {
          float: right;
          margin-left: 10px;
        }
      }
    }
  }
</style>
